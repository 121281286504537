import { Component, effect, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { toSignal } from "@angular/core/rxjs-interop";
import { TranslationService } from "src/app/core";
import { ButtonComponent } from "../button/button.component";

@Component({
  selector: "app-file-viewer",
  standalone: true,
  imports: [PdfViewerModule, ButtonComponent],
  templateUrl: "./file-viewer.component.html",
  styleUrl: "./file-viewer.component.scss",
})
export class FileViewerComponent {
  private translationService = inject(TranslationService);

  dialogRef = inject(MatDialogRef<FileViewerComponent>);
  data = inject(MAT_DIALOG_DATA);
  isImageValid = false;

  currentLanguage = toSignal(this.translationService.currentLanguage$);
  isEnglish = true;

  constructor() {
    effect(() => {
      this.isEnglish = this.currentLanguage() === "en";
    });
  }

  ngOnInit(): void {
    this.isValidImageUrl(this.data.photo.url).then((isValid) => {
      this.isImageValid = isValid;
    });
  }

  isValidImageUrl(url: string): Promise<boolean> {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  }

  close() {
    this.dialogRef.close();
  }
}
