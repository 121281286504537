import { DateTime } from "ts-luxon";
import { MatInputModule } from "@angular/material/input";
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { Component, forwardRef, Input, ViewEncapsulation } from "@angular/core";
import { NgxMatTimepickerFormatType, NgxMatTimepickerModule } from "ngx-mat-timepicker";

import { ReactiveFormsBaseComponent } from "../../base-components";

@Component({
  selector: "app-timepicker",
  standalone: true,
  imports: [ReactiveFormsModule, MatInputModule, NgxMatTimepickerModule],
  templateUrl: "./timepicker.component.html",
  styleUrl: "./timepicker.component.scss",
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimepickerComponent),
      multi: true,
    },
  ],
})
export class TimepickerComponent extends ReactiveFormsBaseComponent {
  @Input() minTime!: string | DateTime;
  @Input() maxTime!: string | DateTime;
  @Input() format: NgxMatTimepickerFormatType = 24;
}
