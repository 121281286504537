import { Languages } from "src/app/core";

export const environment = {
  production: false,
  DOMAIN: "https://nationalmuseumbookingapi.databoat.app/",
  DOMAIN_API: "https://nationalmuseumbookingapi.databoat.app/api/v1/",
  attachmentAppDownload: "https://nationalmuseumbookingapi.databoat.app/Api/v1/Files/downloadWithAppCode/",
  attachmentAppCode: "NationalMuseumBooking",
  defaultLanguage: Languages.EN,
  currency: "OMR",
  tokenPropertyName: "NationalMuseumBookingToken",
  mainTheme: "light", // "dark" | "light"
  signalR: {
    interval: 5,
    intervals: 10,
  },
};
