import { Routes } from "@angular/router";

import { packagesManagementRoutes, packagesListRoutes } from "./nested-features/packages";
import { guidedToursManagementRoutes, guidedToursRoutes } from "./nested-features/guided-tours-programs";

import { Permissions } from "src/assets/config/config";
import { hallsManagementRoutes, hallsRoutes } from "./nested-features/halls";
import { museumBuildingsManagementRoutes, museumBuildingsRoutes } from "./nested-features/museum-buildings";
import { productsManagementRoutes, productsRoutes } from "./nested-features/products";
import { groupOffersManagementRoutes, groupOffersRoutes } from "./nested-features/group-offers";
import { visitorPricesManagementRoutes, visitorPricesRoutes } from "./nested-features/visitor-prices";
import { audioDevicesManagementRoutes, audioDevicesRoutes } from "./nested-features/audio-devices";
import { couponsManagementRoutes, couponsRoutes } from "./nested-features/coupons";

export const servicesManagementRoutes: Routes = [
  {
    path: "",
    title: "SERVICES.SERVICES_MANAGEMENT",
    data: {
      tabs: [
        {
          key: "Packages",
          title: "SERVICES.Packages",
          routerLink: "packages",
        },
        {
          key: "GuidedTours",
          title: "SERVICES.GuidedTours",
          routerLink: "guided-tours",
        },
        {
          key: "Products",
          title: "SERVICES.Products",
          routerLink: "products",
        },
        {
          key: "VisitorPrices",
          title: "VISITOR_PRICES.TITLE",
          routerLink: "visitor-prices",
        },
        {
          key: "MuseumBuildings",
          title: "MUSEUM_BUILDINGS.TITLE",
          routerLink: "museum-buildings",
        },
        {
          key: "GroupOffers",
          title: "GROUP_OFFERS.TITLE",
          routerLink: "group-offers",
        },
        {
          key: "Halls",
          title: "HALLS.TITLE",
          routerLink: "halls",
        },
        {
          key: "AudioDevices",
          title: "AUDIO_DEVICES.TITLE",
          routerLink: "audio-devices",
        },
        {
          key: "coupons",
          title: "COUPONS.TITLE",
          routerLink: "coupons",
        },
      ],
    },
    loadComponent: () =>
      import("src/app/shared/base-components/base-tabs-management/base-tabs-management.component").then(
        (c) => c.BaseTabsManagementComponent,
      ),
    children: [
      { path: "", redirectTo: "packages", pathMatch: "full" },
      {
        path: "packages",
        data: { permissions: Permissions.Packages },
        children: packagesListRoutes,
      },
      {
        path: "guided-tours",
        data: { permissions: Permissions.GuidedTours },
        children: guidedToursRoutes,
      },
      {
        path: "products",
        data: { permissions: Permissions.Product },
        children: productsRoutes,
      },
      {
        path: "visitor-prices",
        data: { permissions: Permissions.VisitorPrices },
        children: visitorPricesRoutes,
      },
      {
        path: "museum-buildings",
        data: { permissions: Permissions.Museums },
        children: museumBuildingsRoutes,
      },
      {
        path: "group-offers",
        data: { permissions: Permissions.GroupOffers },
        children: groupOffersRoutes,
      },
      {
        path: "halls",
        data: { permissions: Permissions.Halls },
        children: hallsRoutes,
      },
      {
        path: "audio-devices",
        data: { permissions: Permissions.AudioDevices },
        children: audioDevicesRoutes,
      },
      {
        path: "coupons",
        data: { permissions: Permissions.Coupons },
        children: couponsRoutes,
      },
    ],
  },
  {
    path: "packages",
    data: { permissions: Permissions.Packages },
    children: packagesManagementRoutes,
  },
  {
    path: "guided-tours",
    data: { permissions: Permissions.GuidedTours },
    children: guidedToursManagementRoutes,
  },
  {
    path: "products",
    data: { permissions: Permissions.Product },
    children: productsManagementRoutes,
  },
  {
    path: "visitor-prices",
    data: { permissions: Permissions.VisitorPrices },
    children: visitorPricesManagementRoutes,
  },
  {
    path: "museum-buildings",
    data: { permissions: Permissions.Museums },
    children: museumBuildingsManagementRoutes,
  },
  {
    path: "group-offers",
    data: { permissions: Permissions.GroupOffers },
    children: groupOffersManagementRoutes,
  },
  {
    path: "halls",
    data: { permissions: Permissions.Halls },
    children: hallsManagementRoutes,
  },
  {
    path: "audio-devices",
    data: { permissions: Permissions.AudioDevices },
    children: audioDevicesManagementRoutes,
  },
  {
    path: "coupons",
    data: { permissions: Permissions.Coupons },
    children: couponsManagementRoutes,
  },
];
