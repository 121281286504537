import { arEG, enUS, th } from "date-fns/locale";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { Component, EventEmitter, inject, Input, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { DateFnsAdapter, MAT_DATE_FNS_FORMATS, provideDateFnsAdapter } from "@angular/material-date-fns-adapter";
import { MatCalendar, MatCalendarCellClassFunction, MatDatepickerModule } from "@angular/material/datepicker";

import { IsEnglishDirective } from "../../directives";

import { CustomCalendarHeaderComponent } from "src/app/features/book-service/components/steps/tour-date/components";

class CustomDateAdapter extends DateFnsAdapter {
  override getDayOfWeekNames(style: "long" | "short" | "narrow") {
    if (this.locale === enUS) return ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

    return ["أحد", "إثنين", "ثلاثاء", "أربعاء", "خميس", "جمعة", "سبت"];
  }
}

@Component({
  selector: "app-calendar",
  standalone: true,
  imports: [MatDatepickerModule, IsEnglishDirective],
  templateUrl: "./calendar.component.html",
  styleUrl: "./calendar.component.scss",
  encapsulation: ViewEncapsulation.None,
  providers: [
    provideDateFnsAdapter(),
    { provide: MAT_DATE_LOCALE, useValue: enUS },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE] },
  ],
})
export class CalendarComponent {
  private dateAdapter = inject(DateAdapter<DateFnsAdapter>);

  @Input() dateFilter!: (date: Date) => boolean;
  @Input() dateClass!: MatCalendarCellClassFunction<Date>;


  @Output() selected = new EventEmitter<void>();

  @ViewChild(MatCalendar) calendar!: MatCalendar<Date>;

  customCalendarHeaderComponent = CustomCalendarHeaderComponent;

  setCalendarLocale(isEnglish: boolean) {
    this.dateAdapter.setLocale(isEnglish ? enUS : arEG);
    this.calendar?.updateTodaysDate();
  }

  onSelect(date:any) {
    this.selected.emit(date);
  }
}
