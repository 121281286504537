<div class="modal-container d-flex flex-column overflow-hidden">
  <div class="modal-header bg-header justify-content-between align-items-center w-100" mat-dialog-header>
    <h1 class="m-0 p-0 font-24 text-dark w-100 text-center" mat-dialog-title>
      {{ isEnglish ? data.nameEn : data.nameAr }}
    </h1>
  </div>

  <div class="px-4 py-3 m-0" mat-dialog-content>
    @if (isImageValid) {
      <img class="w-100 p-3" [src]="data.photo.url" alt="Product Photo" />
    } @else {
      <pdf-viewer [src]="data.photo.url" [render-text]="true" style="display: block; width: 100%; height: 50vh"> </pdf-viewer>
    }
  </div>

  <div class="modal-footer w-100 d-flex justify-content-end p-3" mat-dialog-actions>
    <app-button btnLabel="ACTIONS.CLOSE" btnClass="font-14 p-3" color="primary" (onClick)="close()" />
  </div>
</div>
