import { MatRadioModule } from "@angular/material/radio";
import { TranslateModule } from "@ngx-translate/core";
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { Component, EventEmitter, Input, Output, forwardRef } from "@angular/core";

import { Checkbox } from "src/app/core";
import { LocalizedNamePipe } from "../../pipes";
import { ReactiveFormsBaseComponent } from "../../base-components";

@Component({
  selector: "app-radio-button",
  standalone: true,
  imports: [MatRadioModule, ReactiveFormsModule, TranslateModule, LocalizedNamePipe],
  templateUrl: "./radio-button.component.html",
  styleUrl: "./radio-button.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true,
    },
  ],
})
export class RadioButtonComponent extends ReactiveFormsBaseComponent {
  @Input({ required: true }) label!: string;
  @Input({ required: true }) options: Checkbox[] = [];
  @Input() isHorizontal = true;

  @Output() isChecked: EventEmitter<Checkbox["id"]> = new EventEmitter();
}
