import { Routes } from "@angular/router";

export const hallsRoutes: Routes = [
  {
    path: "",
    title: "HALLS.TITLE",
    loadComponent: () => import("./pages/halls-list/halls-list.component").then((m) => m.HallsListComponent),
  },
];

export const hallsManagementRoutes: Routes = [
  {
    path: "add",
    title: "HALLS.ADD",
    loadComponent: () => import("./pages/add-edit-hall/add-edit-hall.component").then((m) => m.AddEditHallComponent),
  },
  {
    path: "edit/:id",
    title: "HALLS.EDIT",
    loadComponent: () => import("./pages/add-edit-hall/add-edit-hall.component").then((m) => m.AddEditHallComponent),
  },
];
