<button
  mat-raised-button
  class="mat-mdc-button {{ btnClass }}"
  [ngClass]="{ 'icon-only mat-icon-button mat-button-base': !btnLabel }"
  [type]="type"
  [color]="color"
  [disabled]="disabled"
  [ariaLabel]="btnLabel | translate"
  (click)="onClick.emit()"
  (mouseenter)="onHover.emit()"
>
  <div
    class="d-flex align-items-center gap-2"
    [class.flex-row-reverse]="iconPosition === 'right'"
    [class.justify-content-center]="!btnLabel"
  >
    <ng-content></ng-content>

    @if (btnLabel) {
      <span class="mat-mdc-button-label">{{ btnLabel | translate }} </span>
    }
  </div>
</button>

<!-- Usage
  <app-button [label]="'label'" (onClick)="onClick()"></app-button>
-->
