import { Routes } from "@angular/router";

export const couponsRoutes: Routes = [
  {
    path: "",
    title: "COUPONS.TITLE",
    loadComponent: () => import("./pages/coupons-list/coupons-list.component").then((m) => m.CouponsListComponent),
  },
];

export const couponsManagementRoutes: Routes = [
  {
    path: "add",
    title: "COUPONS.ADD",
    loadComponent: () => import("./pages/add-edit-coupon/add-edit-coupon.component").then((m) => m.AddEditCouponComponent),
  },
  {
    path: "edit/:id",
    title: "COUPONS.EDIT",
    loadComponent: () => import("./pages/add-edit-coupon/add-edit-coupon.component").then((m) => m.AddEditCouponComponent),
  },
];
