import { Routes } from "@angular/router";

export const auditMonitorsManagementRoutes: Routes = [
  {
    path: "",
    title: "AUDIT_MONITOR.TITLE",
    loadComponent: () => import("./pages/audit-monitors-list/audit-monitors-list.component").then((c) => c.AuditMonitorsListComponent),
  },
  {
    path: "view/:id",
    title: "AUDIT_MONITOR.VIEW",
    loadComponent: () => import("./pages/view-audit-monitor/view-audit-monitor.component").then((c) => c.ViewAuditMonitorComponent),
  },
];
