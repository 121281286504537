import { Injectable } from "@angular/core";

import { Event, EventInvitation, EventPayload } from "src/app/features/admin/pages/exhibitions-and-events";
import { HttpService, HttpServiceBaseCRUD, GetPagedBody, GetPagedResponse } from "src/app/core";

@Injectable({
  providedIn: "root",
})
export class EventsService extends HttpService implements HttpServiceBaseCRUD {
  protected override get baseUrl(): string {
    return "events/";
  }

  add(body: EventPayload) {
    return this.post<Event>({ APIName: "add", body, showAlert: true });
  }

  update(body: EventPayload) {
    return this.put<Event>({ APIName: "update", body, showAlert: true });
  }

  remove(id: string) {
    return this.delete<boolean>({ APIName: `deletesoft/${id}`, showAlert: true });
  }

  getById(id: string) {
    return this.get<Event>({ APIName: `get/${id}` });
  }

  getAll() {
    return this.get<Event[]>({ APIName: "getall" });
  }

  getPaged(body: GetPagedBody<Event>) {
    return this.post<GetPagedResponse<Event[]>>({ APIName: "get-paged", body });
  }

  sendEventInvitations(body: EventInvitation) {
    return this.post<string>({ APIName: "send-event-invitations", body });
  }
}
