import { toSignal } from "@angular/core/rxjs-interop";
import { MatError } from "@angular/material/form-field";
import { TranslateModule } from "@ngx-translate/core";
import { Component, effect, inject } from "@angular/core";
import { AbstractControl, ControlContainer, FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";

import {
  InputTextComponent,
  DropdownComponent,
  DatepickerComponent,
  InputCountComponent,
  ButtonComponent,
  ValidationHandlerPipe,
} from "src/app/shared";
import { MembershipCardPayload } from "src/app/features/admin";
import { LookupsService, TypedForm, TypedFormGroup } from "src/app/core";

const SHARED = [InputTextComponent, InputCountComponent, DropdownComponent, DatepickerComponent, ButtonComponent];

@Component({
  selector: "app-company-request-details",
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule, MatError, ValidationHandlerPipe, ...SHARED],
  templateUrl: "./company-request-details.component.html",
  styleUrl: "./company-request-details.component.scss",
})
export class CompanyRequestDetailsComponent {
  private fb = inject(FormBuilder);
  private lookupsService = inject(LookupsService);
  private controlContainer = inject(ControlContainer);

  cardCost = 22.5;
  countries = toSignal(this.lookupsService.getCountries());

  get form() {
    return this.controlContainer.control as FormGroup;
  }

  get totalNumberOfCards() {
    return this.form.get("totalNumberOfCards")?.value;
  }

  get totalAmount() {
    return this.form?.get("totalAmount");
  }

  get membershipCards() {
    return this.form.get("membershipCards") as FormArray<TypedFormGroup<MembershipCardPayload>>;
  }

  get totalNumberOfMembers() {
    return this.membershipCards.value.reduce((acc, card) => acc + (card.number ? card.number : 0), 0);
  }

  private get membershipCardPlanId() {
    return this.form.get("membershipCardPlanId");
  }

  constructor() {
    this.lookupsService.assignMembershipCardPlans();

    effect(() => {
      const membershipCardPlans = this.lookupsService.membershipCardPlans;

      if (membershipCardPlans) {
        const companyPlan = membershipCardPlans()?.find((plan) => plan.code === "1YC");
        this.membershipCardPlanId?.setValue(companyPlan?.id);
      }
    });
  }

  /**
   * Adds a new membership card form to the membershipCards array,
   * applies the membershipCardsValidator to the array, and updates
   * the value and validity of the membershipCards array.
   */
  confirmCardsCount() {
    if (this.totalNumberOfCards < this.membershipCards.length) {
      Array.from({ length: this.membershipCards.length - this.totalNumberOfCards }).forEach(() =>
        this.membershipCards.removeAt(this.membershipCards.length - 1),
      );
    } else {
      Array.from({ length: this.totalNumberOfCards - this.membershipCards.length }).forEach(() =>
        this.membershipCards.push(this.membershipCardForm()),
      );
    }

    this.membershipCards.addValidators([this.membershipCardsCountValidator, this.membershipCardsNationalityValidator]);
    this.membershipCards.updateValueAndValidity();

    this.totalAmount?.setValue(this.totalNumberOfCards * this.cardCost);
  }

  /**
   * Adds a new membership card form to the list of membership cards.
   * After adding the new form, it updates the value and validity of the membership cards.
   */
  addCard() {
    this.membershipCards.push(this.membershipCardForm());
    this.membershipCards.updateValueAndValidity();
  }

  /**
   * Removes a membership card from the list at the specified index and updates the form's validity.
   *
   * @param index - The index of the membership card to remove.
   */
  removeCard(index: number) {
    this.membershipCards.removeAt(index);
    this.membershipCards.updateValueAndValidity();
  }

  /**
   * Validates the number of membership cards against the total number of members.
   *
   * @returns An object with an `invalidCardsCount` property set to `true` if the total number of members exceeds the total number of cards, otherwise `null`.
   */
  private membershipCardsCountValidator = () => {
    if (this.totalNumberOfCards !== this.membershipCards.length) return { invalidCardsCount: true };

    return null;
  };

  /**
   * Validator function to check for duplicate nationality IDs in a FormArray of membership cards.
   *
   * @param control - The form control to validate, expected to be a FormArray of TypedFormGroup<MembershipCardPayload>.
   * @returns An object with a `duplicateNationalityId` property set to true if duplicates are found, otherwise null.
   */
  private membershipCardsNationalityValidator = (control: AbstractControl) => {
    const cards = control as FormArray<TypedFormGroup<MembershipCardPayload>>;
    const nationalityIds = cards.controls.map((card) => card.value.nationalityId);

    const hasDuplicateNationalityId = nationalityIds.some((id, index) => nationalityIds.indexOf(id) !== index);
    if (hasDuplicateNationalityId) return { duplicateNationalityId: true };

    return null;
  };

  /**
   * Creates a form group for the membership card details.
   *
   * @returns A form group with controls for membership card number and nationality ID.
   *
   * - `number`: A form control initialized to 0 with required and minimum value validators.
   * - `nationalityId`: A form control initialized to null with a required validator.
   */
  private membershipCardForm() {
    return this.fb.group<TypedForm<MembershipCardPayload>>({
      number: this.fb.control(0, [Validators.required, Validators.min(1)]),
      nationalityId: this.fb.control(null, Validators.required),
    });
  }
}
