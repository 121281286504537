import { UserRole } from "src/app/core";

const Events = [UserRole.EVENTAPPROVER, UserRole.EVENTAUDITOR, UserRole.EVENTCREATOR];

export const Permissions = {
  Kiosk: [UserRole.KIOSK],
  Home: [UserRole.CUSTOMER],
  Services: [UserRole.CUSTOMER, UserRole.COMPANY, UserRole.GUEST],
  MyTickets: [UserRole.CUSTOMER, UserRole.COMPANY],
  Admin: [UserRole.ADMIN, UserRole.COUNTER, UserRole.HALLAPPROVER, UserRole.STATISTICUSER, UserRole.FINANCIALUSER, ...Events],
  Packages: [UserRole.ADMIN],
  GuidedTours: [UserRole.ADMIN],
  ServicesManagement: [UserRole.ADMIN],
  UsersManagement: [UserRole.ADMIN],
  Halls: [UserRole.ADMIN],
  Events: [UserRole.ADMIN, ...Events],
  Nationalities: [UserRole.ADMIN],
  VisitorCategories: [], // UserRole.ADMIN
  Memberships: [UserRole.ADMIN],
  Product: [UserRole.ADMIN],
  Reports: [UserRole.ADMIN, UserRole.STATISTICUSER, UserRole.FINANCIALUSER],
  Audit: [UserRole.ADMIN],
  SpecialTickets: [UserRole.ADMIN, UserRole.COUNTER],
  BookingServices: [UserRole.ADMIN, UserRole.COUNTER],
  Reservations: [UserRole.ADMIN, UserRole.COUNTER],
  Calendar: [UserRole.ADMIN, UserRole.COUNTER],
  HallsReservations: [UserRole.HALLAPPROVER],
  Gates: [UserRole.ADMIN],
  Requests: [UserRole.ADMIN],
  VisitorPrices: [UserRole.ADMIN],
  Museums: [UserRole.ADMIN],
  GroupOffers: [UserRole.ADMIN],
  AudioDevices: [UserRole.ADMIN],
  Coupons: [UserRole.ADMIN],
  LearningCenter: [UserRole.ADMIN],
  Dashboard: [UserRole.ADMIN],
};
