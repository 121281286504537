import { Routes } from "@angular/router";
import { myTicketsRoutes } from "src/app/features/my-tickets";
import { blockDaysManagementRoutes, blockDaysRoutes } from "./block-days";

const myReservationsBaseRoute = myTicketsRoutes[0].children![0];
const myReservationsDetails = myTicketsRoutes[1];

export const reservationsRoutes: Routes = [
  {
    path: "",
    title: "TICKETS.RESERVATIONS",
    data: {
      tabs: [
        {
          key: "services",
          title: "BLOCK_DAYS.ALL",
          routerLink: "services",
        },
        {
          key: "block-days",
          title: "BLOCK_DAYS.TITLE",
          routerLink: "block-days",
        }
      ],
    },
    loadComponent: () =>
      import("src/app/shared/base-components/base-tabs-management/base-tabs-management.component").then(
        (c) => c.BaseTabsManagementComponent,
      ),
    children: [
      { path: "", redirectTo: "services", pathMatch: "full" },
      {
        path: "services",
        children: [myReservationsBaseRoute],
      },
      {
        path: "block-days",
        children: blockDaysRoutes,
      }
    ],
  },
  {
    path: "view",
    children: [myReservationsDetails],
  },
  {
    path: "block-days",
    children: blockDaysManagementRoutes,
  }
];
