import { Injectable } from "@angular/core";
import { GetPagedBody, GetPagedResponse, HttpService, HttpServiceBaseCRUD } from "src/app/core";

@Injectable({
  providedIn: "root",
})
export class AudioDevicesService extends HttpService implements HttpServiceBaseCRUD {
  protected override get baseUrl(): string {
    return "audioguidance/";
  }

  add(body: any) {
    return this.post<any>({ APIName: "add", body, showAlert: true });
  }

  update(body: any) {
    return this.put<any>({ APIName: "update", body, showAlert: true });
  }

  remove(id: string) {
    return this.delete<boolean>({ APIName: `deletesoft/${id}`, showAlert: true });
  }

  getById(id: string) {
    return this.get<any>({ APIName: `get-id/${id}` });
  }

  getAll() {
    return this.get<any[]>({ APIName: "getall" });
  }

  getPaged(body: GetPagedBody<any>) {
    return this.post<GetPagedResponse<any[]>>({ APIName: "getpaged", body });
  }
}
