import { Routes } from "@angular/router";

const eventTypesManagementRoutes: Routes = [
  {
    path: "add",
    title: "EVENTS.ADD_NEW_EVENT_TYPE",
    loadComponent: () => import("./pages/add-edit-event-type/add-edit-event-type.component").then((m) => m.AddEditEventTypeComponent),
  },
  {
    path: "edit/:id",
    title: "EVENTS.EDIT_EVENT_TYPE",
    loadComponent: () => import("./pages/add-edit-event-type/add-edit-event-type.component").then((m) => m.AddEditEventTypeComponent),
  },
];

export const eventsListRoutes: Routes = [
  {
    path: "",
    title: "EVENTS.TITLE",
    loadComponent: () =>
      import("./pages/exhibitions-and-events-list/exhibitions-and-events-list.component").then((m) => m.ExhibitionsAndEventsListComponent),
  },
  {
    path: "add",
    title: "EVENTS.ADD_NEW_EVENT",
    loadComponent: () => import("./pages/add-edit-event/add-edit-event.component").then((m) => m.AddEditEventComponent),
  },
  {
    path: "edit/:id",
    title: "EVENTS.EDIT_EVENT",
    loadComponent: () => import("./pages/add-edit-event/add-edit-event.component").then((m) => m.AddEditEventComponent),
  },
  {
    path: "types",
    children: eventTypesManagementRoutes,
  },
];
