import { Routes } from "@angular/router";

export const groupOffersRoutes: Routes = [
  {
    path: "",
    title: "GROUP_OFFERS.TITLE",
    loadComponent: () => import("./pages/group-offers-list/group-offers-list.component").then((m) => m.GroupOffersListComponent),
  },
];

export const groupOffersManagementRoutes: Routes = [
  {
    path: "add",
    title: "GROUP_OFFERS.ADD",
    loadComponent: () => import("./pages/add-edit-group-offer/add-edit-group-offer.component").then((m) => m.AddEditGroupOfferComponent),
  },
  {
    path: "edit/:id",
    title: "GROUP_OFFERS.EDIT",
    loadComponent: () => import("./pages/add-edit-group-offer/add-edit-group-offer.component").then((m) => m.AddEditGroupOfferComponent),
  },
];
