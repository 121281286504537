import { Routes } from "@angular/router";

export const audioDevicesRoutes: Routes = [
  {
    path: "",
    title: "AUDIO_DEVICES.TITLE",
    loadComponent: () => import("./pages/audio-devices-list/audio-devices-list.component").then((m) => m.AudioDevicesListComponent),
  },
];

export const audioDevicesManagementRoutes: Routes = [
  {
    path: "add",
    title: "AUDIO_DEVICES.ADD",
    loadComponent: () => import("./pages/add-edit-audio-device/add-edit-audio-device.component").then((m) => m.AddEditAudioDeviceComponent),
  },
  {
    path: "edit/:id",
    title: "AUDIO_DEVICES.EDIT",
    loadComponent: () => import("./pages/add-edit-audio-device/add-edit-audio-device.component").then((m) => m.AddEditAudioDeviceComponent),
  },
];
