import { DatePipe } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { TableLazyLoadEvent, TableModule } from "primeng/table";
import { NavigationEnd, Router, RouterLink } from "@angular/router";
import { debounceTime, distinctUntilChanged, filter, take } from "rxjs";
import { Component, EventEmitter, Input, Output, ViewEncapsulation, inject } from "@angular/core";

import { ButtonComponent } from "../button/button.component";
import { DeleteModalComponent } from "../delete-modal/delete-modal.component";

import { ExportService, TableOptions, TableService } from "src/app/core";

@Component({
  selector: "app-table",
  standalone: true,
  imports: [DatePipe, TranslateModule, TableModule, RouterLink, ButtonComponent],
  templateUrl: "./table.component.html",
  styleUrl: "./table.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent {
  private dialog = inject(MatDialog);
  private tableService = inject(TableService);
  private router = inject(Router);
  private exportService = inject(ExportService);

  // Inputs
  @Input() tableOptions!: TableOptions;

  // fields
  /* hold Columns Definitions */
  cols: TableOptions["inputCols"] = [];
  /* hold actions */
  actions: TableOptions["inputActions"] = [];
  /* hold the urls fro get and delete */
  url!: TableOptions["inputUrl"];
  /* hold bodyOptions */
  bodyOptions: TableOptions["bodyOptions"] = {
    pageNumber: 1,
    pageSize: 10,
  };

  /* hold the data */
  data: Record<string, unknown>[] = [];
  /* total count of records */
  totalCount = 0;
  /* hold the component name */
  componentName!: string | undefined;
  /* hold the permissions */
  permissions: string[] = [];

  /* load data at first time */
  private firstInit!: boolean;

  @Output() rows = new EventEmitter<any[]>();

  constructor() {
    this.columnSearchInput();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntilDestroyed(),
      )
      .subscribe((event: any) => {
        if (!event.url.includes("edit") && !event.url.includes("add")) this.loadDataFromServer();
      });
  }

  ngOnInit(): void {
    this.initializeInputs();
  }

  initializeInputs(): void {
    // initialize url from config service
    this.url = this.tableOptions.inputUrl;

    // initialize columns
    const cols = this.tableOptions.inputCols.filter((col) => this.tableOptions.responsiveDisplayedProperties.includes(col.field));
    if (innerWidth <= 768) this.cols = cols;
    else this.cols = this.tableOptions.inputCols;
    this.actions = this.tableOptions.inputActions;
    if (this.tableOptions.bodyOptions) this.bodyOptions = structuredClone(this.tableOptions.bodyOptions);
    this.permissions = this.tableOptions.permissions?.listOfPermissions || [];
    this.componentName = this.tableOptions.permissions?.componentName;
  }

  /* get search value when typing on column search box */
  columnSearchInput(): void {
    this.tableService.searchNew$.pipe(debounceTime(1000), distinctUntilChanged(), takeUntilDestroyed()).subscribe(() => {
      this.firstInit ? this.loadDataFromServer() : (this.firstInit = true);
    });
  }

  // load data from server
  loadDataFromServer(): void {
    if (this.url.getAllMethod === "GET") {
      this.tableService.getData(this.url.getAll).subscribe((res) => ((this.data = res.data), this.rows.emit(this.data)));
    } else if (this.url.getAllMethod === "POST") {
      this.tableService.postData(this.url.getAll, this.bodyOptions).subscribe((res: any) => {
        this.data = res.data.data;
        this.rows.emit(this.data);
        this.totalCount = res.data.totalCount;
      });
    }
  }

  /* lazy load table data */
  /* note:  gets called on entering component */
  loadLazyLoadedData(event?: TableLazyLoadEvent): void {
    this.resetOpt();
    this.setSortColumn(event);
    this.setPaging(event);
    this.loadDataFromServer();
  }

  // get cell data
  getCellData(row: any, property: any): any {
    let mainProperty, subProperty;

    if (property.includes(".")) {
      // Ex. task.name
      mainProperty = property.split(".").shift() as string;
      subProperty = property.split(".").pop() as string;

      if (mainProperty.includes("[0]")) {
        // Ex. roles[0].name
        const arrayProperty = mainProperty.split("[0]").shift() as string;

        if (row[arrayProperty][0]) return row[arrayProperty][0][subProperty] ? row[arrayProperty][0][subProperty] : "-";
        else return "-";
      }

      return row[mainProperty] ? row[mainProperty][subProperty] ?? "-" : "-";
    } else {
      mainProperty = property;

      return row.hasOwnProperty(mainProperty) ? row[mainProperty] ?? "-" : "-";
    }
  }

  /* set SortColumn */
  setSortColumn(event?: TableLazyLoadEvent): void {
    this.bodyOptions = {
      ...this.bodyOptions,
      orderByValue: [
        {
          colId: event?.sortField as string,
          sort: event?.sortOrder === 1 ? "asc" : "desc",
        },
      ],
    };
  }

  /* set paging parameters*/
  setPaging(event?: TableLazyLoadEvent): void {
    if (event?.rows) {
      this.bodyOptions = {
        ...this.bodyOptions,
        pageSize: (event?.rows as number) || 10,
        pageNumber: (event?.first as number) / (event?.rows as number) + 1 || 1,
      };
    }
  }

  /* reset server opt */
  resetOpt(): void {
    if (this.tableOptions.bodyOptions) this.bodyOptions = this.tableOptions.bodyOptions;
    else {
      this.bodyOptions = {
        pageNumber: 1,
        pageSize: 10,
      };
    }
  }

  // Delete Modal
  openDeleteModal(id: string): void {
    const modal = this.dialog.open(DeleteModalComponent, {
      panelClass: ["small", "p-0"],
    });

    const subscription = modal.afterClosed().subscribe((dialogResult: boolean) => {
      if (dialogResult) {
        this.deleteById(id);
      }

      subscription.unsubscribe();
    });
  }

  // deleteByAppId
  deleteByAppId(id?: any): void {
    this.tableService
      .delete(this.url.delete as string, id)
      .pipe(take(1))
      .subscribe(() => this.loadDataFromServer());
  }

  // deleteById
  deleteById(id?: any): void {
    this.tableService
      .delete(this.url.delete as string, id)
      .pipe(take(1))
      .subscribe(() => this.loadDataFromServer());
  }

  // Filter
  filter(target: any, column: any, filterColumnName: string, dataType: string): void {
    this.resetOpt();
    target.value = this.convertDataType(target.value, dataType);

    if (!this.bodyOptions?.filter) this.bodyOptions!.filter = {};

    if (filterColumnName !== undefined && filterColumnName !== "" && filterColumnName !== null) {
      this.tableService.searchNew$.next((this.bodyOptions!.filter[filterColumnName] = target.value));
    } else {
      this.tableService.searchNew$.next((this.bodyOptions!.filter[column] = target.value));
    }
  }

  // convertDataType
  convertDataType(value: string, dataType?: string): string | number {
    if (dataType === "number") return +value;

    return value;
  }

  get isFilterEnabled() {
    return this.cols.some((col) => col.filter);
  }

  getFinalRoute(actionRoute: string | undefined, id: string) {
    if (!actionRoute?.includes("{") && !actionRoute?.includes("}")) return `${actionRoute + id}`;

    if (actionRoute?.includes("{") && actionRoute?.includes("}")) return actionRoute.replace(/{id}/g, id);

    return "";
  }

  export(sheetDetails: { [k: string]: string }, fileName: string) {
    const sheetColumnsValues = Object.keys(sheetDetails);

    const newArray = this.data.map((eachData, index) => {
      let eachRow = {};

      sheetColumnsValues.map((eachColumnValue) => {
        eachRow = {
          ...eachRow,
          ...{ "#": index + 1 },
          [sheetDetails[eachColumnValue]]: eachData[eachColumnValue],
        };
      });

      return eachRow;
    });

    this.exportService.excel(newArray, fileName);
  }

  /* when leaving the component */
  ngOnDestroy() {
    this.tableService.searchNew$.next(null);
    this.resetOpt();
    this.firstInit = false;
  }
}
