import { Routes } from "@angular/router";

export const blockDaysRoutes: Routes = [
  {
    path: "",
    title: "BLOCK_DAYS.TITLE",
    loadComponent: () => import("./pages/block-days-list/block-days-list.component").then((m) => m.BlockDaysListComponent),
  }
]

export const blockDaysManagementRoutes: Routes = [
  {
    path: "",
    title: "BLOCK_DAYS.TITLE",
    loadComponent: () => import("./pages/block-days-list/block-days-list.component").then((m) => m.BlockDaysListComponent)
  },
  {
    path: "add",
    title: "BLOCK_DAYS.ADD",
    loadComponent: () => import("./pages/add-block-days/add-block-days.component").then((m) => m.AddBlockDaysComponent)
  },
]
