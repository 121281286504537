<div class="mat-mdc-form-field-wrapper form-group d-flex flex-column m-0">
  <div class="d-flex flex-column">
    @if (label) {
      <label [for]="formControlName" class="text-primary fw-bold font-14 mb-2">{{ label | translate }}</label>
    }

    <mat-radio-group
      class="d-flex {{ isHorizontal ? 'flex-md-row align-items-md-center gap-md-4' : 'flex-md-column' }} flex-column"
      aria-label="Select an option"
      [id]="formControlName"
      [color]="color"
      [formControl]="control"
      (change)="isChecked.emit($event.value)"
    >
      @for (option of options; track $index) {
        <mat-radio-button [value]="option.id">
          {{ option | localizedName }}
        </mat-radio-button>
      }
    </mat-radio-group>
  </div>
</div>
