import { Injectable } from "@angular/core";

import { Hall, HallPayload } from "../../interfaces";

import { GetPagedBody, GetPagedResponse, HttpService, HttpServiceBaseCRUD } from "src/app/core";

@Injectable({
  providedIn: "root",
})
export class HallsService extends HttpService implements HttpServiceBaseCRUD {
  protected override get baseUrl(): string {
    return "halls/";
  }

  getAll() {
    return this.get<any[]>({ APIName: "getall" });
  }

  getById(id: string | number) {
    return this.get<Hall>({ APIName: `get/${id}` });
  }

  add(body: HallPayload) {
    return this.post<boolean>({ APIName: "add", body, showAlert: true });
  }

  update(body: HallPayload) {
    return this.put<boolean>({ APIName: "update", body, showAlert: true });
  }

  remove(id: string) {
    return this.delete<boolean>({ APIName: `deletesoft/${id}`, showAlert: true });
  }

  getPaged(body: GetPagedBody<Partial<Hall>>) {
    return this.post<GetPagedResponse<Hall[]>>({ APIName: "getpaged", body });
  }
}
